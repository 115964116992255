import React from "react";
import { NewsType } from "../../types/news";
import dayjs from "dayjs";
import {
  FeaturedContainer,
  FeaturedLink,
  FeaturedImage,
  FeaturedButton,
  FeaturedContent,
  FeaturedDescription,
} from "../../styles/CardComponents";

const FeaturedNewsCard = ({ item }: { item: NewsType }) => {
  return (
    <FeaturedContainer>
      <FeaturedLink href={item.url} target="__blank">
        <FeaturedImage src={item.image.url}></FeaturedImage>
      </FeaturedLink>
      <FeaturedContent>
        <FeaturedLink target="__blank" href={item.url}>
          <span>{item.title}</span>
        </FeaturedLink>
        <FeaturedDescription style={{ marginTop: "0px" }}>
          {dayjs(item.publishedOn).format("MMMM DD, YYYY")}
        </FeaturedDescription>
        <FeaturedDescription>{item.description}</FeaturedDescription>
        <div style={{ marginTop: "25px" }}>
          <FeaturedButton
            onClick={() => {
              window && window.open(item.url, "__blank");
            }}
            href={item.url}
            target="__blank"
          >
            Read More
          </FeaturedButton>
        </div>
      </FeaturedContent>
    </FeaturedContainer>
  );
};

export default FeaturedNewsCard;
