import React from "react";
import dayjs from "dayjs";
import {
  FeaturedContainer,
  FeaturedLink,
  FeaturedImage,
  FeaturedButton,
  FeaturedContent,
  FeaturedDescription,
} from "../../styles/CardComponents";

const FeaturedBlogCard = ({ item }: { item: any }) => {
  const url = `/blog-posts/${item.slug}`;

  return (
    <FeaturedContainer>
      <FeaturedLink href={url}>
        <FeaturedImage
          src={`${item.featuredImage.file.url}?h=${600}`}
        ></FeaturedImage>
      </FeaturedLink>
      <FeaturedContent>
        <FeaturedLink href={url}>
          <span>{item.title}</span>
        </FeaturedLink>
        <FeaturedDescription style={{ marginTop: "0px" }}>
          {dayjs(item.publishedOn).format("MMMM DD, YYYY")}
        </FeaturedDescription>
        <FeaturedDescription>{item.description}</FeaturedDescription>
        <div style={{ marginTop: "25px" }}>
          <FeaturedButton href={url} target="__blank">
            Read More
          </FeaturedButton>
        </div>
      </FeaturedContent>
    </FeaturedContainer>
  );
};

export default FeaturedBlogCard;
