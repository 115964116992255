import { graphql } from "gatsby";
import * as React from "react";

import Layout from "../components/Layout/Layout";
import News from "../components/News/News";
import Seo from "../components/seo";

const NewsPage = (props) => {
  const { allContentfulBlogPost: blogPosts } = props.data;
  return (
    <Layout vertical={true}>
      <Seo title="News" />
      <News blogData={blogPosts.nodes} />
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query BlogPosts {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost {
      nodes {
        title
        updatedAt
        featured
        slug
        publishedOn
        featuredImage {
          file {
            url
            fileName
            contentType
          }
        }
      }
    }
  }
`;
