import dayjs from "dayjs";
import React from "react";
// import { BlogType } from "../../types/blog";
import {
  CardWrapper,
  ResourceImg,
  CardBody,
  NewsTitle,
  NewsSubtitle,
} from "../../styles/CardComponents";

const BlogCard = ({ item }: { item: any }) => {
  return (
    <CardWrapper href={`/blog-posts/${item.slug}`} target="__blank">
      {item.featuredImage && (
        <ResourceImg
          height={400}
          width={400}
          src={`${item.featuredImage.file.url}?w=${300}&h=${300}`}
        />
      )}
      <CardBody>
        <NewsTitle>{item.title}</NewsTitle>
        <NewsSubtitle>
          {dayjs(item.publishedOn).format("MMMM DD, YYYY")}
        </NewsSubtitle>
      </CardBody>
    </CardWrapper>
  );
};

export default BlogCard;
