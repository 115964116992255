import React from "react";
import {
  Callout,
  CalloutContentWrapper,
  StyledTitle,
} from "../../styles/StyledComponents";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import NewsCard from "./NewsCard";
import { useDetectDevice } from "../../util/hooks";
import FeaturedNewsCard from "./FeaturedNewsCard";
import BlogCard from "../BlogPost/BlogCard";
import FeaturedBlogCard from "../BlogPost/FeaturedBlogCard";
import dayjs from "dayjs";
import { BlogType } from "../../types/news";
import ResourceCard from "../ResourcesPage/ResourceCard";

const NewsPageTitle = styled(StyledTitle)`
  font-family: "Lato";
  color: ${colors.lightBlue};
  border-bottom: 5px solid ${colors.orange};
  padding-bottom: 20px;
`;

const NewsTitleContainer = styled(Callout)`
  @media only screen and (max-width: 478px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 0px;
  }
`;

const NewsCardsWrapper = styled(Callout)`
  align-self: center;

  @media only screen and (min-width: 1440px) {
    max-width: 1440px;
  }

  padding-top: 0px;
  padding-left: 150px;
  padding-right: 150px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 40px;
  row-gap: 40px;

  align-items: stretch;

  @media only screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    grid-template-columns: 1fr;
    justify-content: center;
  }
`;

const AllPostsContainer = styled(Callout)`
  padding-top: 0px;
  padding-left: 150px;
  padding-right: 150px;
`;

const PostsWrapper = styled.div`
  @media only screen and (min-width: 1440px) {
    max-width: 1400px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 35px;
  row-gap: 45px;

  align-items: stretch;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
`;

// const CenterWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
// `;

const NewsCardsContainer = styled(CalloutContentWrapper)`
  display: grid;
  row-gap: 25px;
  align-items: start;
  grid-auto-rows: minmax(min-content, max-content);

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 35px;
    column-gap: 25px;
  }

  @media only screen and (max-width: 478px) {
    grid-template-columns: 1fr;
    /* row-gap: 10px; */
  }
`;

const FeaturedNewsContainer = styled.div``;

const NEWS_QUERY = gql`
  query GetExternalNews {
    newsPostExternalCollection(order: publishedOn_DESC) {
      items {
        primaryFeatured
        title
        url
        publishedOn
        description
        image {
          url
        }
      }
    }
  }
`;

const News = ({ blogData }: { blogData: [BlogType] }) => {
  const { loading, data: newsData } = useQuery(NEWS_QUERY);
  const { isMobile } = useDetectDevice();

  const maxPosts = 4;

  let featuredPost: any;
  let featuredBlog: any;

  if (!loading) {
    featuredPost = newsData.newsPostExternalCollection.items.filter(
      (item: any, _i) => {
        return item.primaryFeatured;
      }
    )[0];
  }

  featuredBlog = blogData.filter((item) => {
    return item.featured;
  })[0];

  const sortedPosts = blogData
    .filter((item) => !item.featured)
    .sort((a, b) => (dayjs(a.publishedOn).isBefore(b.publishedOn) ? 1 : -1))
    .slice(0, maxPosts);

  const remainingPosts = blogData.filter(
    (item) => !sortedPosts.includes(item) && featuredBlog !== item
  );

  return (
    <>
      <NewsTitleContainer type="white">
        <CalloutContentWrapper>
          <NewsPageTitle>LATEST POSTS</NewsPageTitle>
        </CalloutContentWrapper>
      </NewsTitleContainer>
      <NewsCardsWrapper type="white">
        <FeaturedNewsContainer>
          {featuredBlog && <FeaturedBlogCard item={featuredBlog} />}
        </FeaturedNewsContainer>
        <NewsCardsContainer>
          {sortedPosts.map((item, i) => (
            <BlogCard key={`blog_card_${i}`} item={item} />
          ))}
        </NewsCardsContainer>
      </NewsCardsWrapper>
      <NewsTitleContainer type="white">
        <CalloutContentWrapper>
          <NewsPageTitle>
            {isMobile ? "NEWS" : "DEBRIS TRACKER IN THE NEWS"}
          </NewsPageTitle>
        </CalloutContentWrapper>
      </NewsTitleContainer>
      <NewsCardsWrapper type="white">
        <FeaturedNewsContainer>
          {featuredPost && <FeaturedNewsCard item={featuredPost} />}
        </FeaturedNewsContainer>
        <NewsCardsContainer>
          {!loading &&
            newsData.newsPostExternalCollection.items
              .filter((item, _i) => !item.primaryFeatured)
              .slice(0, maxPosts)
              .map((item: any, i: number) => <NewsCard key={i} item={item} />)}
        </NewsCardsContainer>
      </NewsCardsWrapper>
      {remainingPosts.length > 0 && (
        <NewsTitleContainer type="grey">
          <NewsPageTitle>ALL POSTS</NewsPageTitle>
        </NewsTitleContainer>
      )}
      {remainingPosts.length > 0 && (
        <AllPostsContainer type="grey">
          <PostsWrapper>
            {remainingPosts.map((item, i) => (
              <ResourceCard
                key={i}
                resource={{
                  highlightImage: {
                    url: item.featuredImage.file.url,
                    title: item.title,
                    width: 500,
                    height: 500,
                  },
                  description: dayjs(item.updatedAt).format("MMMM DD, YYYY"),
                  name: item.title,
                  url: `/blog-posts/${item.slug}`,
                }}
              />
            ))}
          </PostsWrapper>
        </AllPostsContainer>
      )}
    </>
  );
};

export default News;
