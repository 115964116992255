import styled from "styled-components";
import { colors } from "./colors";
import { StyledTitle } from "./StyledComponents";

export const FeaturedContainer = styled.div`
  height: 100%;
`;

export const FeaturedImage = styled.img`
  object-fit: cover;
  // max-width: none;
  width: 100%;
  transition: transform 250ms ease;
  max-height: 600px;
  border-radius: 0px;

  &:hover {
    transform: translate(0px, -3px);
  }

  @media only screen and (max-width: 478px) {
    max-height: 250px;
  }
`;

export const FeaturedContent = styled.div`
  padding-top: 30px;

  @media only screen and (max-width: 478px) {
    padding-top: 10px;
  }
`;

export const FeaturedLink = styled.a`
  text-decoration: none;
  font-size: 35px;
  font-weight: 800;
  line-height: 40px;
  color: ${colors.lightBlue};
  cursor: pointer;
  transition: all 250ms ease;
  display: flex;

  &:hover {
    color: ${colors.orange};
  }

  @media only screen and (max-width: 478px) {
    font-size: 25px;
  }
`;

export const FeaturedDescription = styled.p`
  font-size: 18px;
  line-height: 1.8em;
  color: ${colors.lightBlue};
`;

export const FeaturedButton = styled.a`
  padding: 20px;
  border-radius: 0px;
  font-size: 16pt;
  border: none;
  align-self: center;
  color: ${colors.white};
  background-color: #edbd7f;
  min-width: 120px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  user-select: none;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 478px) {
    padding: 15px;
    font-size: 14pt;
    font-weight: bold;
  }
`;

export const CardWrapper = styled.a`
  text-decoration: none;
  max-height: 250px;
  width: 100%;
  background-color: ${colors.white};
  cursor: pointer;

  transition: all 200ms ease;

  overflow: hidden;

  display: grid;
  grid-template-columns: 0.75fr 1fr;
  grid-gap: 24px;
  align-items: stretch;

  @media only screen and (min-width: 768px) {
    &:hover {
      transform: translate(0px, -3px);
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    max-height: none;
  }

  @media only screen and (max-width: 478px) {
    grid-gap: 5px;
    margin: 10px 0px;
  }
`;

export const CardBody = styled.div``;

export const ResourceImg = styled.img`
  object-fit: cover;
  max-width: 100%;
  height: 200px;
  border-radius: 0px;
`;

export const NewsTitle = styled(StyledTitle)`
  font-family: "Lato";
  color: ${colors.lightBlue};
  line-height: 1.2;
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.1;
  }

  @media only screen and (max-width: 478px) {
    margin-top: 0px;
    font-weight: bold;
    font-size: 12pt;
  }
`;

export const NewsSubtitle = styled(NewsTitle)`
  margin-top: 10px;
  font-size: 14px;

  @media only screen and (max-width: 478px) {
    font-weight: normal;
    margin-top: 0px;
  }
`;
