import dayjs from "dayjs";
import React from "react";
import { NewsType } from "../../types/news";
import {
  CardWrapper,
  ResourceImg,
  CardBody,
  NewsTitle,
  NewsSubtitle,
} from "../../styles/CardComponents";

const NewsCard = ({ item }: { item: NewsType }) => {
  return (
    <CardWrapper href={item.url} target="__blank">
      {item.image && (
        <ResourceImg height={400} width={400} src={item.image.url} />
      )}
      <CardBody>
        <NewsTitle>{item.title}</NewsTitle>
        <NewsSubtitle>
          {dayjs(item.publishedOn).format("MMMM DD, YYYY")}
        </NewsSubtitle>
      </CardBody>
    </CardWrapper>
  );
};

export default NewsCard;
