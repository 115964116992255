import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { StyledTitle } from "../../styles/StyledComponents";

interface ResourceType {
  name: string;
  url: string;
  description: string;
  highlightImage: {
    height: number;
    width: number;
    url: string;
    title: string;
  };
}

const CardWrapper = styled.a`
  text-decoration: none;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 28%);
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  cursor: pointer;
  border-radius: 0px;
  min-height: 430px;
  min-width: 350px;

  transition: 250ms ease all;

  &:hover {
    transform: translate(0px, -10px);
  }

  @media only screen and (max-width: 478px) {
    min-width: none;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 25px;
  overflow: hidden;
`;

const ResourceDescription = styled.span`
  color: ${colors.lightBlue};
  font-family: "Lato";
  line-height: 1.2;
  font-size: 12pt;
`;

const ResourceImg = styled.img`
  object-fit: cover;
  object-position: 50% 25%;
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

const ResourceTitle = styled(StyledTitle)`
  font-family: "Lato";
  font-size: 25px;
  font-weight: bold;
  color: ${colors.lightBlue};
  line-height: 1.1;
  margin-bottom: 15px;

  @media only screen and (max-width: 767px) {
    font-size: 18pt;
    line-height: 1.1;
  }
`;

const ResourceCard = ({ resource }: { resource: ResourceType }) => {
  let imgSize = 250;

  return (
    <CardWrapper href={resource.url} target="__blank">
      <ResourceImg
        height={imgSize * 1}
        width={imgSize}
        src={resource.highlightImage.url}
      />
      <CardBody>
        <ResourceTitle>{resource.name}</ResourceTitle>
        <ResourceDescription>{resource.description}</ResourceDescription>
      </CardBody>
    </CardWrapper>
  );
};

export default ResourceCard;
